<template>
  <v-main>
    <v-container grid-list-xs>
      <v-card>
        <v-card-title primary-title> 欢迎来到主新闻中心（MPC） </v-card-title>
        <v-card-text>
          <p>代表们可以通过以下链接访问此委员会主新闻中心已发布内容</p>
          <p v-if="this.$store.state.pro === false" class="black--text">
            你还不是PRO用户，代表们将只能看到三条MPC内容。请联系模时购买。
          </p>
          <span class="black--text">
            {{ mpcViewLink }}
          </span>
        </v-card-text>
        <v-card-text>
          <p>
            主新闻中心成员可以通过此链接发布MPC稿件 <br />
            <strong> 请勿 </strong>
            将此链接分享给非MPC成员。
          </p>
          <span class="black--text">
            {{ mpcPublishLink }}
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="emptyMPC">清空MPC</v-btn>
        </v-card-actions>
      </v-card>
      <p class="text-h5 mt-4">发布内容</p>
      <v-row>
        <v-col>
          <span v-if="mpcs.length === 0"> 尚未发布内容 </span>
          <span v-else>
            <div v-for="(mpc, index) in mpcs" :key="index">
              <v-card class="mb-4">
                <v-card-title primary-title> {{ mpc.title }} </v-card-title>
                <v-card-subtitle>
                  {{ mpc.press }} | {{ mpc.time }}
                </v-card-subtitle>
                <v-card-text>
                  <span v-if="content[index].readmore"
                    >{{ content[index].content.slice(0, 100) }}
                  </span>
                  <a
                    class=""
                    v-if="content[index].readmore"
                    @click="content[index].readmore = false"
                    href="#"
                  >
                    Read more...
                  </a>
                  <span
                    v-if="!content[index].readmore"
                    v-html="content[index].content"
                  ></span>
                </v-card-text>
              </v-card>
            </div>
          </span>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import db from "@/plugins/leancloud";
export default {
  name: "mpc",
  metaInfo() {
    return {
      title: "MPC",
    };
  },
  data() {
    return {
      content: [],
      mpcs: [],
    };
  },
  watch: {
    mpcs() {
      this.calculateReadmore();
    },
  },
  computed: {
    mpcViewLink() {
      const l =
        "https://session.munshare.com/mpcview/" +
        this.$store.state.currentConfId;
      return l;
    },
    mpcPublishLink() {
      var encodedString = window.btoa(this.$store.state.currentConfId);
      const l =
        "https://session.munshare.com/mpc/publish/" +
        this.$store.state.currentConfId +
        "/" +
        encodedString;
      return l;
    },
  },
  methods: {
    async emptyMPC() {
      const answer = window.confirm("确定清空？所有MPC内容将清空并不可撤回");
      if (answer) {
        const id = this.$store.state.currentConfId;
        if (id) {
          var conf = db.Object.createWithoutData("sessionConferences", id);
          await conf.set("mpcs", []).save();
          this.fetchMPC();
        }
      }
    },
    calculateReadmore() {
      let d = [];
      if (this.mpcs.length > 0) {
        d = this.mpcs.map((item) => {
          let o = {
            content: item.content,
            readmore: item.content.length > 100,
          };
          return o;
        });
      }
      this.content = d;
    },
    fetchMPC() {
      this.loading = false;
      const query = new db.Query("sessionConferences");
      query
        .equalTo("objectId", this.$store.state.currentConfId)
        .select(["mpcs"])
        .find()
        .then((resp) => {
          console.log(resp);
          const obj = JSON.parse(JSON.stringify(resp[0]));
          console.log(obj.mpcs);
          this.mpcs = obj.mpcs;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.fetchMPC();
  },
};
</script>
<i18n>
{
  "en": {
    "account": "Account",
    "reset": "Reset",
    "resetConfirm":"Are you sure to clear data and restart?",
    "confirm": "Confirm",
    "about-session": "About Session",
    "about-munshare": "About Munshare",
    "immediateUse":"Chair MUN Online",
    "login":"Login",
    "munshare": "Munshare",
    "showPro": "Show Pro Logo",
    "hidePro": "Hide Pro Logo",
    "resetSession":"Clear the data and restore Session",
    "showConferenceName": "Display your conference name",
    "darkMode":"Dark Mode",
    "setTheme":"Set Theme",
    "themeColor":"Theme Color",
    "Thanks":"Thanks for your support"

  },
  "zh": {
    "account": "个人中心",
    "reset": "重置",
    "resetConfirm":"确定清除所有数据并重启App么？",
    "about-session": "关于模时Session",
    "confirm": "确定",
    "about-munshare": "关于模时",
    "showConferenceName": "会议名称展示",
    "showPro": "显示Pro Logo",
    "hidePro": "隐藏Pro Logo",
    "munshare": "模时",
    "immediateUse":"随开随用的模联议程",
    "resetSession":"重置Session全部数据，恢复原始状态。",
    "login":"登录",
    "setTheme":"主题设置",
    "darkMode":"夜间模式",
    "themeColor":"主题颜色",
    "Thanks":"感谢你使用Session Pro。如有任何使用问题，请联系模时。"
  }
}
</i18n>
